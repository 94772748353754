import { useContext } from 'react'
import * as styledComponents from 'styled-components'
import { Theme } from './Theme'
import { ThemedStyledComponentsModule, ThemeContext } from 'styled-components'
import { breakpoint } from 'styled-components-breakpoint'

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  withTheme
} = styledComponents as ThemedStyledComponentsModule<typeof Theme>

const useTheme: () => typeof Theme = () => {
  return useContext(ThemeContext)
}

export { styled, css, createGlobalStyle, keyframes, ThemeProvider, withTheme, useTheme, breakpoint }
