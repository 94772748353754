import rgba from 'polished/lib/color/rgba'
import * as React from 'react'
import { useMakeImagePath } from '../../../../hooks/useMakeImagePath'
import { styled } from '../../../lib/styled-components'
import { SectionHeadline, SectionHeadlineWithMargin } from '../../shared/Elements'
import { HugeArrowButton } from '../../shared/HugeArrowButton'

interface IProps {
  phase: number
  onClose?: () => any
  onPreviousClick?: () => any
  onNextClick?: () => any
  nextAvailable?: boolean
  previousAvailable?: boolean
}

export const PhaseDetails = ({ phase, onClose, onNextClick, onPreviousClick, previousAvailable, nextAvailable }: IProps) => {
  const makeImagePath = useMakeImagePath()

  return (<Container>
    <HugeArrowButton previous disabled={!previousAvailable} onClick={onPreviousClick} />
    <HugeArrowButton next disabled={!nextAvailable} onClick={onNextClick} />

    <CloseIcon src={makeImagePath('/images/icons/close.svg')} onClick={onClose} />

    <Top>
      <SectionHeadlineWithMargin>Phase {phase}</SectionHeadlineWithMargin>
      {phase === 1 && <div>
        <p>Trial & Error</p>
        <p>Jägermeister is new in the market. The key Jägermeister brand building tools are being introduced and the concepts are being fine tuned step by step through trial and error</p>
      </div>}
      {phase === 2 && <div>
        <p>Building the foundations</p>
        <p>The Jägermeister brand building tools are expanded and the brand starts to get some traction in the market.</p>
      </div>}
      {phase === 3 && <div>
        <p>Tipping point</p>
        <p>Consumers are building an emotional connection to the brand which gathers momentum. New drinkers discover Jägermeister and the viral word of mouth effect helps the brand take off. Absolute growth is rapid</p>
      </div>}
      {phase === 4 && <div>
        <p>High level plateau</p>
        <p>After becoming the number 1 shot brand in the market, Jägermeister has a strong base of fans and drinkers. Brand awareness and usage are high.  The brand still has an underground image, but it has mass market appeal and sales.</p>
      </div>}
      {phase === 5 && <div>
        <p>Innovation & Re-build</p>
        <p>The brand starts to decline, naturally or in some cases because of the bomb/high energy effect. Jägermeister  requires innovation and new news. New drinking occasions and consumers need to be identified. The drinks strategy needs to be broadened.</p>
      </div>}
    </Top>

    <Bottom>
      <Left>
        <SectionHeadlineWithMargin>Marketing</SectionHeadlineWithMargin>

        {phase === 1 && <div>
          <p>Experimenting with the Jägermeister Marketing Toolbox:</p>

          <ul>
            <li>ICE COLD STRATEGY</li>
            <li>Tap Machines & Visibility</li>
            <li>Jägerettes (trial)</li>
            <li>Digital & Social content</li>
            <li>Events & Music</li>
          </ul>

          <p>Target:</p>

          <ul>
            <li>Awareness</li>
            <li>Trial</li>
            <li>Recruitment</li>
          </ul>
        </div>}

        {phase === 2 && <div>
          <p>Continue the successful use of Marketing Toolbox:</p>

          <ul>
            <li>ICE COLD STRATEGY</li>
            <li>Tap Machines & Visibility</li>
            <li>Jägerettes (trial)</li>
            <li>Digital & Social content</li>
            <li>Events & Music</li>
          </ul>

          <p>Target:</p>

          <ul>
            <li>Awareness</li>
            <li>Trial</li>
            <li>Recruitment</li>
            <li>Repurchase</li>
          </ul>
        </div>}

        {phase === 3 && <div>
          <p>The Jägermeister brand building tools are expanded and the scale is increased:  </p>

          <ul>
            <li>Strategic Brand Messaging</li>
            <li>Digital content</li>
            <li>BTL campaigns & PR</li>
            <li>Trade Marketing activities</li>
            <li>Events, Music, Festivals</li>
          </ul>

          <p>Target:</p>

          <ul>
            <li>Recruitment</li>
            <li>Repurchase</li>
            <li>Relevance</li>
            <li>Brand Equity</li>
          </ul>
        </div>}

        {phase === 4 && <div>
          <p>The entire toolbox is in use, activities are bigger & better:</p>

          <ul>
            <li>360° campaign (ATL, BTL, Digital,  PR, etc.)</li>
            <li>Large scale Trade Marketing</li>
            <li>Innovation (Product & Marketing) &  Drink Strategy</li>
            <li>T&S and Hubertus Circle</li>
          </ul>

          <p>Target:</p>

          <ul>
            <li>Relevance & Loyalty</li>
            <li>Brand Equity</li>
          </ul>
        </div>}

        {phase === 5 && <div>
          <p>The toolbox needs to be re-energized/re-invented. Keep consumers longer with JGM</p>

          <ul>
            <li>Innovation (Product & Marketing)</li>
            <li>360° campaign (ATL, BTL, Digital, PR, etc.)</li>
            <li>Experiential Marketing</li>
          </ul>

          <p>Target:</p>

          <ul>
            <li>Recruitment</li>
            <li>Loyalty</li>
            <li>Brand Equity</li>
            <li>New occasions</li>
          </ul>
        </div>}
      </Left>
      <Right>
        <SectionHeadlineWithMargin>Commercial</SectionHeadlineWithMargin>

        {phase === 1 && <div>
          <p>Distribution:</p>
          <ul>
            <li>On trade focus in 1st tier cities</li>
            <li>Select and target lighthouse outlets</li>
            <li>Start to build first wholesale business</li>
          </ul>
        </div>}

        {phase === 2 && <div>
          <p>Distribution:</p>
          <ul>
            <li>On trade focus in 1st tier cities and selected 2nd tier cities</li>
            <li>Develop more lighthouse accounts</li>
            <li>Stronger penetration of wholesaler</li>
            <li>Off trade listing in first key accounts</li>
          </ul>
        </div>}

        {phase === 3 && <div>
          <p>Distribution:</p>
          <ul>
            <li>On trade focus, but spread nationwide</li>
            <li>Fully expand off trade listings</li>
            <li>Full reach via wholesale universe</li>
            <li>Professional KAM applied</li>
            <li>First E-Commerce activities depending on market</li>
          </ul>
        </div>}

        {phase === 4 && <div>
          <p>Distribution:</p>

          <ul>
            <li>all major off trade channels</li>
            <li>all Key Accounts</li>
            <li>Duty Free</li>
            <li>E-Commerce</li>
          </ul>

          <p>Develop SKU Strategy (Price, Size and Channel)</p>
        </div>}

        {phase === 5 && <div>
          <p>Distribution:</p>

          <ul>
            <li>Nationwide presence, very high distribution</li>
            <li>Available in all offline & online sales channels</li>
            <li>Leverage distribution network to launch Jägermeister Innovations</li>
          </ul>
        </div>}
      </Right>
    </Bottom>
  </Container>)
}

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${props => props.theme.textColor};
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1.1px;
`

const CloseIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 3;
`

const Top = styled.div`
  border-bottom: 1px solid ${props => rgba(props.theme.textColor, 0.4)};
  padding-bottom: 30px;
  margin-bottom: 30px;
`
const Bottom = styled.div`
  display: flex;
  flex-direction: row;
`

const Left = styled.div`
  flex: 1;
`
const Right = styled.div`
  flex: 1;
`

const SectionHeadlineWithMargin = styled(SectionHeadline)`
  margin-bottom: 30px;
`
