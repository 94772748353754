import * as React from 'react'
import { keyframes, styled } from '../../lib/styled-components'

interface IProps {
  full?: boolean
  inline?: boolean
  centered?: boolean
  className?: string
}

export const LoadingOverlay = ({ full, inline, centered, className }: IProps) => {
  return (<Container {...{ full, inline, centered, className }}>
    <Ring {...{full, inline}} />
  </Container>)
}

const Container = styled.div<IProps>`
  ${props => props.full ? `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  ` : (!props.inline && `
    position: absolute;
    top: 0;
    width: 100%;
  `)};
  ${props => props.centered && 'text-align: center;'}
`

const ringAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const Ring = styled.div<IProps>`
  display: inline-block;
  width: 64px;
  height: 64px;
  ${props => !props.inline && `position: absolute;
  top: ${props.full ? '50%' : '100px'};
  left: 50%;`};
  margin-left: -32px;
  margin-top: -32px;
  
  &:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: ${ringAnimation} 1.2s linear infinite;
  }
`
