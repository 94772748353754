import { useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'
import { ExpertViewScreen } from './application/components/screens/ExpertViewScreen'
import { LifeCycleScreen } from './application/components/screens/LifeCycleScreen'
import { LoginScreen } from './application/components/screens/LoginScreen'
import { ValidationScreen } from './application/components/screens/ValidationScreen'
import { Header } from './application/components/shared/Header'
import { LoadingOverlay } from './application/components/shared/LoadingOverlay'
import { styled, ThemeProvider } from './application/lib/styled-components'
import { Theme } from './application/lib/Theme'
import { IConfig } from './application/lib/Types'
import { ConfigProvider } from './hooks/useConfig'

interface IProps {
  config: IConfig
}

const Application = (props: IProps) => {
  const config = useMemo(() => Object.assign({}, props.config), [])
  const { oauthToken } = config
  const isAuthenticated = oauthToken !== null

  config.baseUrl = {
    'local': 'http://localhost',
    'production': 'https://product-lifecycle.gtmplatform.de',
    'staging': 'https://product-lifecycle-stage.gtmplatform.de'
  }[process.env.APP_ENV]

  const [loading, setLoading] = useState(isAuthenticated)
  useEffect(() => {
    if (!isAuthenticated) return
    (async () => {
      const response = await fetch(config.baseUrl + '/api/countries/mine', {
        headers: {
          'Authorization': 'Bearer ' + oauthToken
        }
      })
      const json = await response.json()
      if (response.status !== 200) {
        if (json.message === 'Invalid token') {
          location.href = '/oauth'
        }
      } else {
        config.country = json
        setLoading(false)
      }
    })()
  }, [])

  return (<ConfigProvider value={config}>
    <ThemeProvider theme={Theme}>
      <HashRouter>
        <Container>
          {config.showHeader && <Header loggedIn={isAuthenticated} />}
          {loading
            ? <LoadingOverlay />
            : <Switch>
              <Route path="/expert-view" render={() => {
                if (!isAuthenticated) return <Redirect to="/login" />
                return <ExpertViewScreen />
              }} />
              <Route path="/validate-assignment"  render={() => {
                if (!isAuthenticated) return <Redirect to="/login" />
                return <ValidationScreen />
              }} />
              <Route path="/login"><LoginScreen /></Route>
              <Route path="/"  render={() => {
                if (!isAuthenticated) return <Redirect to="/login" />
                return <LifeCycleScreen />
              }} />
            </Switch>}
        </Container>
      </HashRouter>
    </ThemeProvider>
  </ConfigProvider>)
}

const Container = styled.div`
  font-family: ${props => props.theme.primaryFontFamily};
`;

(window as any).ProductCycleApplication =  class ProductCycleApplication {
  private _container: HTMLElement
  private _config: IConfig

  constructor (container: HTMLElement, config: IConfig) {
    this._container = container
    this._config = config

    this.run()
  }

  run () {
    ReactDOM.render(<Application config={this._config} />, this._container)
  }
}
