import * as React from 'react'
import { CSSArrow } from '../../../lib/CSSArrow'
import { styled } from '../../../lib/styled-components'
import { SmallButton } from '../../shared/Elements'

interface IProps {
  position: { x: number|string, y: number|string }
  label: string
  buttonLabel?: string
  onButtonClick?: () => any
  className?: string
}

export const PhaseTooltip = ({ className, position, label, buttonLabel, onButtonClick }: IProps) => {
  return (<Container style={{ left: position.x, top: position.y }} className={className}>
    <Label>{label}</Label>
    {buttonLabel && <SmallButton onClick={onButtonClick}>{buttonLabel}</SmallButton>}
  </Container>)
}

const Container = styled.div`
  width: 255px;
  background: ${props => props.theme.textColor};
  border-radius: 2px;
  padding: 20px;
  transform: translateY(-100%) translateX(-20px);
  ${props => CSSArrow('bottom', 10, props.theme.textColor)};
  position: absolute;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  &:after {
    left: 20px;
  }
`

const Label = styled.div`
  letter-spacing: 1.6px;
  font-family: ${props => props.theme.primaryFontFamily};
  color: ${props => props.theme.darkGreenColor};
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
`
