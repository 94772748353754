import { useState } from 'react'
import * as React from 'react'
import { styled } from '../../lib/styled-components'
import { ToggleButton } from '../shared/Elements'

export const LoginScreen = () => {
  const [loading, setLoading] = useState(false)

  const handleLoginClicked = () => {
    if (loading) return
    location.href = "/oauth"
    setLoading(true)
  }

  return (<Container>
    <ToggleButton onClick={handleLoginClicked} disabled={loading}>{loading ? "Loading..." : "Login via UDB"}</ToggleButton>
  </Container>)
}

const Container = styled.div`
  text-align: center;
  padding: 30px;
`
