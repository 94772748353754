import { CSSArrow } from '../../lib/CSSArrow'
import { styled } from '../../lib/styled-components'

export const ScreenContainer = styled.div`
  position: relative;
  padding-top: 32px;
`

export const Headline = styled.h1<{ large?: boolean, centered?: boolean }>`
  text-transform: uppercase;
  font-family: ${props => props.theme.primaryFontFamily};
  font-size: ${props => props.large ? '60px' : '20px'};
  line-height: ${props => props.large ? '63px' : '36px'};
  font-weight: bold;
  color: ${props => props.theme.textColor};
  margin: 0;
  ${props => props.large && 'letter-spacing: -2px'};
  ${props => props.centered && 'text-align: center'};
`

export const SmallButton = styled.div<{ outlined?: boolean }>`
  font-family: ${props => props.theme.primaryFontFamily};
  text-transform: uppercase;
  background: ${props => props.outlined ? 'transparent' : props.theme.primaryColor};
  color: ${props => props.theme.textColor};
  ${props => props.outlined && 'border: 1px solid white'};
  border-radius: 1px;
  font-weight: bold;
  letter-spacing: 1.3px;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  padding: 0 15px;
`

export const LargeButton = styled.div<{ outlined?: boolean }>`
  font-family: ${props => props.theme.primaryFontFamily};
  text-transform: uppercase;
  background: ${props => props.outlined ? 'transparent' : props.theme.primaryColor};
  color: ${props => props.theme.textColor};
  ${props => props.outlined && 'border: 1px solid white'};
  border-radius: 2px;
  font-weight: bold;
  letter-spacing: 1.3px;
  font-size: 13px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  padding: 0 32px;
  text-decoration: none;
`

export const SectionHeadline = styled.h1`
  font-family: ${props => props.theme.primaryFontFamily};
  text-transform: uppercase;
  color: ${props => props.theme.textColor};
  font-weight: bold;
  font-size: 28px;
  letter-spacing: 2.28px;
  line-height: 30px;
  position: relative;
  z-index: 1;
  padding-left: 20px;
  &:before {
    content: '';
    position: absolute;
    width: 120px;
    height: 13px;
    background: ${props => props.theme.primaryColor};
    top: 18px;
    left: 0;
    z-index: -1;
  }
`

export const Axis = styled.div<{ left?: boolean; top?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.primaryColor};
  color: ${props => props.theme.textColor};
  ${props => props.left && `
    width: 18px;
    left: -51px;
    bottom: 0;
    top: 20px;
    ${CSSArrow('top', 9, props.theme.primaryColor)};
  `};
  ${props => props.top && `
    height: 18px;
    top: -51px;
    right: 20px;
    left: 0;
    ${CSSArrow('right', 9, props.theme.primaryColor)};
  `};
  position: absolute;
`

export const AxisLabel = styled.div<{ rotated?: boolean }>`
  font-family: ${props => props.theme.primaryFontFamily};
  text-transform: uppercase;
  ${props => props.rotated && 'transform: rotate(-90deg)'};
  font-weight: bold;
  font-size: 13px;
  white-space: nowrap;
  transform-origin: 50% 50%;
`

export const ToggleButton = styled.div<{ enabled?: boolean }>`
  font-family: ${props => props.theme.headlineFont};
  font-weight: bold;
  background: transparent;
  border: 1px solid white;
  color: white;
  font-size: 12px;
  letter-spacing: 4px;
  line-height: 47px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 25px;
  text-align: center;
  display: inline-block;
  &:focus {
    outline: none;
  }
  ${props => props.enabled && `
    border: 1px solid ${props.theme.primaryColor};
    background: ${props.theme.primaryColor};
  `}
`
