export type TExpertViewContent = {
  [key: number]: {
    headline: string
    yAxisLabel: string
    additionalInfo: string
    showSizes?: boolean
    phaseNote?: string
    phaseNotePosition?: number
    items: { [key: number]: { label: string, highlighted?: boolean }[] }
  }
}

export const ExpertViewContent: TExpertViewContent = {
  1: {
    headline: 'Commercial Overview',
    yAxisLabel: 'Commercial Functions',
    additionalInfo: 'Professional KAM and E-Com management are key success factors for a long partnership',
    phaseNote: 'Professional KAM and E-Commerce are the key success factors for phase 3 and beyond.',
    phaseNotePosition: 3,
    items: {
      1: [
        { label: 'On Trade Sales' },
        { label: 'CRM System & Reporting' }
      ],
      2: [
        { label: 'On Prem Trade Marketing' },
        { label: 'Expand On Trade Sales' },
        { label: 'Off Trade Sales' }
      ],
      3: [
        { label: 'Expand Off Trade Sales' },
        { label: 'Implement E-Commerce', highlighted: true },
        { label: 'Implement KAM', highlighted: true },
        { label: 'Off Trade Trade Marketing' }
      ],
      4: [
        { label: 'Expand E-Commerce' },
        { label: 'Expand KAM' },
        { label: 'Implement Duty Free' }
      ],
      5: [
        { label: 'Further development of commercial functions for executional execellence in all online and offline sales channels' }
      ]
    }
  },
  2: {
    headline: 'Marketing Overview',
    yAxisLabel: 'Marketing Functions',
    additionalInfo: 'Phase 3 and 4 are crucial for building brand equity and start introducing innovations',
    phaseNote: 'Professional Brand Marketing and Innovation are the key success factors for phase 3 and 4.',
    phaseNotePosition: 3.5,
    items: {
      1: [
        { label: 'Brand Ambassador Program' },
        { label: 'Brand Promotion' }
      ],
      2: [
        { label: 'Expand Promotion' },
        { label: 'Implement Digital Marketing' },
        { label: 'Public Relations' }
      ],
      3: [
        { label: 'Professional Brand Marketing', highlighted: true },
        { label: 'Market Research' },
        { label: 'Expand Digital Marketing' },
        { label: 'Implement Trend & Scene' },
        { label: 'Event Marketing' }
      ],
      4: [
        { label: 'Implement Innovation', highlighted: true },
        { label: 'Experiential Marketing' },
        { label: 'Marketing Strategy' },
        { label: 'Media Expertise' }
      ],
      5: [
        { label: 'Further development of marketing functions for executional execellence in all online and offline channels' }
      ]
    }
  },
  3: {
    headline: 'Suggested distributor structure & size by phase',
    yAxisLabel: 'Functions & responsibilities',
    additionalInfo: 'Especially phase 3 and 4 are crucial to develop professional capabilities for further success.',
    showSizes: true,
    items: {
      1: [
        { label: 'On Trade Sales' },
        { label: 'CRM System & Reporting' },
        { label: 'Brand Ambassador Program' },
        { label: 'Brand Promotion' }
      ],
      2: [
        { label: 'On Prem Trade Marketing' },
        { label: 'Expand On Trade Sales' },
        { label: 'Off Trade Sales' },
        { label: 'Expand Promotion' },
        { label: 'Implement Digital Marketing' },
        { label: 'Public Relations' }
      ],
      3: [
        { label: 'Expand Off Trade Sales' },
        { label: 'Implement E-Commerce', highlighted: true },
        { label: 'Implement KAM', highlighted: true },
        { label: 'Off Trade Trade Marketing' },
        { label: 'Professional Brand Marketing', highlighted: true },
        { label: 'Market Research' },
        { label: 'Expand Digital Marketing' },
        { label: 'Implement Trend & Scene' },
        { label: 'Event Marketing' }
      ],
      4: [
        { label: 'Expand E-Commerce' },
        { label: 'Expand KAM' },
        { label: 'Implement Duty Free' },
        { label: 'Implement Innovation', highlighted: true },
        { label: 'Experiential Marketing' },
        { label: 'Marketing Strategy' },
        { label: 'Media Expertise' }
      ],
      5: [
        { label: 'Further development of commercial and marketing fucntions for executional excellence in all online and offline sales channels' }
      ]
    }
  }
}

export const Phases = {
  1: 'Trial & Error',
  2: 'Building the Foundations',
  3: 'Tipping Point',
  4: 'High Level Plateau',
  5: 'Innovation & Re-Build'
}
