import * as React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMakeImagePath } from '../../../hooks/useMakeImagePath'
import { breakpoint, styled } from '../../lib/styled-components'
import { Hamburger } from './Hamburger'

interface IProps {
  loggedIn?: boolean
}

interface IState {
  expanded: boolean
}

export const Header = ({ loggedIn }: IProps) => {
  const [expanded, setExpanded] = useState(false)
  const handleToggle = () => {
    setExpanded(!expanded)
  }
  const handleLogout = () => {
    window.location.href = '/logout'
  }

  const makeImagePath = useMakeImagePath()

  return (<div>
    <Hamburger onClick={handleToggle} active={expanded} />
    <Container expanded={expanded}>
      <Left>
        <Logo src={makeImagePath('/images/logo.png')} />
        <ItemList>
          <Item><ItemLink to='/' as={Link}>About</ItemLink></Item>
        </ItemList>
      </Left>
      <Right>
        <LifeCycleButton>Lifecycle</LifeCycleButton>
        {loggedIn && <Logout onClick={handleLogout}>
          <img src={makeImagePath('/images/logout.svg')} alt="Logout"/>
        </Logout>}
      </Right>
    </Container>
  </div>)
}

const Container = styled.div<{ expanded?: boolean }>`
  position: fixed;
  right: -100%;
  top: 0;
  height: 100vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  background: ${props => props.theme.footerBackgroundColor};
  transition: right 0.3s ease-out;
  justify-content: space-between;
  ${props => props.expanded && 'right: 0;'}
  ${breakpoint('tablet')`
    position: relative;
    right: auto;
    top: auto;
    bottom: auto;
    z-index: 2;
    max-width: 1440px;
    padding: 0 20px;
    margin: auto;
    height: 90px;
    width: auto;
    align-items: center;
    flex-direction: row;
    background: transparent;
  `}
`

const Left = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: 20px 0;
  align-items: center;
  ${breakpoint('tablet')`
    flex-direction: row;
    padding: 0;
  `}
`

const Logo = styled.img`
  width: 50px;
  margin-right: 15px;
  margin-bottom: 20px;
  ${breakpoint('tablet')`
    margin-bottom: 0;
  `}
`

const ItemList = styled.ul`
  width: 100%;
  ${breakpoint('tablet')`
    width: auto;
  `}
`

const Item = styled.li`
  display: block;
  vertical-align: middle;
  ${breakpoint('tablet')`
    display: inline-block;
    margin: 0 3px;
  `}
`

const ItemLink = styled.a<{ enabled?: boolean }>`
  font-family: ${props => props.theme.headlineFont};
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.textColor};

  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  letter-spacing: 2px;
  text-decoration: none;
  ${props => props.enabled && `background: ${props.theme.primaryColor}`};
  padding: 0 13px;
  line-height: 38px;
  height: 38px;
`

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LifeCycleButton = styled.div`
  background: ${props => props.theme.primaryColor};
  color: ${props => props.theme.textColor};
  font-weight: bold;
  letter-spacing: 1.4px;
  font-size: 11px;
  padding: 3px 8px;
  text-transform: uppercase;
`

const Logout = styled.div`
  cursor: pointer;
  margin-left: 20px;
`
