import * as React from 'react'
import { useMakeImagePath } from '../../../hooks/useMakeImagePath'
import { styled } from '../../lib/styled-components'

interface IProps {
  next?: boolean
  previous?: boolean
  disabled?: boolean
  onClick?: () => any
  className?: string
}

export const HugeArrowButton = ({ next, previous, disabled, onClick, className }: IProps) => {
  const makeImagePath = useMakeImagePath()

  return (<Container faded={disabled} left={previous} right={next} onClick={() => !disabled && onClick && onClick()} className={className}>
    <Arrow src={makeImagePath('/images/icons/huge-arrow.svg')} flipped={next} />
    <Text right={next}>
      {!!next && 'Next'}
      {!!previous && 'Previous'}
    </Text>
  </Container>)
}

const Container = styled.div<{ left?: boolean; right?: boolean; faded?: boolean }>`
  position: absolute;
  width: 176px;
  height: 176px;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  ${props => props.faded && `opacity: 0.3`};
  ${props => props.left && `right: 100%; margin-right: 50px;`};
  ${props => props.right && `left: 100%; margin-left: 50px;`};
`

const Arrow = styled.img<{ flipped?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translateX(-50%) translateY(-50%) ${props => props.flipped ? 'scaleX(-1)' : ''};
`

const Text = styled.div<{ right?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translateX(-50%) translateY(-50%);
  text-align: ${props => props.right ? 'left' : 'right'};
  font-family: ${props => props.theme.primaryFontFamily};
  color: ${props => props.theme.textColor};
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1.6px;
  font-size: 13px;
`
