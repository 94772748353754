import * as React from 'react'
import { useMakeImagePath } from '../../../../hooks/useMakeImagePath'
import { CSSArrow } from '../../../lib/CSSArrow'
import { styled } from '../../../lib/styled-components'
import { Headline, SmallButton } from '../../shared/Elements'

interface IProps {
  position: { x: number, y: number }
  phase: number
  onDetailsClick?: () => any
}

export const HoveredPhaseTooltip = ({ position, phase, onDetailsClick }: IProps) => {
  const textData = {
    1: {
      title: "Trial & Error",
      text: "Market saturation with high volume and static or slightly declining growth rates"
    },
    2: {
      title: "Building the Foundations",
      text: "Market saturation with high volume and static or slightly declining growth rates"
    },
    3: {
      title: "Tipping Point",
      text: "Market saturation with high volume and static or slightly declining growth rates"
    },
    4: {
      title: "High Level Plateau",
      text: "Market saturation with high volume and static or slightly declining growth rates"
    },
    5: {
      title: "Innovation & Re-Build",
      text: "Market saturation with high volume and static or slightly declining growth rates"
    }
  }

  const makeImagePath = useMakeImagePath()
  return (<Container style={{ left: position.x, top: position.y }}>
    <TitleContainer>
      <Icon src={makeImagePath('/images/icons/speedometer.svg')} />
      <Title>{textData[phase].title}</Title>
    </TitleContainer>

    <Text>{textData[phase].text}</Text>

    <ButtonRow>
      <DetailsButton outlined onClick={onDetailsClick}>Details</DetailsButton>
    </ButtonRow>
  </Container>)
}

const Container = styled.div`
  max-width: 350px;
  background: ${props => props.theme.primaryColor};
  border-radius: 2px;
  padding: 20px;
  transform: translateY(-100%) translateX(-50%);
  color: ${props => props.theme.textColor};
  ${props => CSSArrow('bottom', 10, props.theme.primaryColor)};
  position: absolute;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 11px;
`

const Icon = styled.img`
  margin-right: 15px;
`

const Title = styled(Headline)`
  font-size: 20px;
  text-transform: none;
  white-space: nowrap;
`

const Text = styled.div`
  font-family: ${props => props.theme.primaryFontFamily};
  font-size: 13px;
  line-height: 30px;
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`

const DetailsButton = styled(SmallButton)`
  display: inline-block;
`
