type TPosition = 'top'|'left'|'bottom'|'right'

const oppositePosition = (position: TPosition): TPosition => {
  switch (position) {
    case 'bottom':
      return 'top'
    case 'left':
      return 'right'
    case 'right':
      return 'left'
    case 'top':
      return 'bottom'
  }
}

export const CSSArrow = (
  position: TPosition,
  arrowSize: number,
  arrowColor: string,
  borderWidth: number = 0,
  borderColor: string = 'white') => {

  const opposite = oppositePosition(position)

  let selectors = '&:after'
  if (borderWidth > 0) {
    selectors += ', &:before'
  }

  return `
    position: relative;
    background: ${arrowColor};
    
    ${selectors} {
      ${opposite}: 100%;
      ${(position === 'top' || position === 'bottom') ? 'left' : 'top'}: 50%;

      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
  
    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-${opposite}-color: ${arrowColor};
      border-width: ${arrowSize}px;
  
      ${(position === 'top' || position === 'bottom') ? 'margin-left' : 'margin-top'}: -${arrowSize}px;
    }
  
    ${borderWidth > 0 && `
      &:before {
        border-color: rgba(255, 255, 255, 0);
        border-${opposite}-color: ${borderColor};
        border-width: ${arrowSize + borderWidth}px;
  
        ${(position === 'top' || position === 'bottom') ? 'margin-left' : 'margin-top'}: -${arrowSize}px;
      }
    `};`
}
