export const ValidationContent = {
  1: [
    'JÄGERMEISTER IS NEW IN YOUR MARKET, THE BRAND IS STILL BEING INTRODUCED STEP BY STEP',
    'THE BRAND IS WELL IMPLEMENTED AND STARTS TO GET SOME TRACTION IN THE MARKET. THE ABSOLUTE VOLUME IS STILL LOW, BUT THE GROWTH POTENTIAL IS HIGH.',
    'JÄGERMEISTER IS WELL RECOGNIZED AND CONSUMERS ARE BUILDING AN EMOTIONAL CONNECTION TO THE BRAND. THE VIRAL WORD OF MOUTH EFFECT HELPS THE BRAND TAKE OFF. THE TARGET GROUP IS INCREASING.',
    'JÄGERMEISTER HAS BECOME THE NUMBER 1 SHOT BRAND IN THE MARKET. THE BASE OF FANS AND DRINKERS IS STRONG. THE BRAND STILL HAS AN UNDERGROUND IMAGE, BUT IT HAS MASS MARKET APPEAL AND SALES. ',
    'THE BRAND STARTS TO DECLINE, NATURALLY OR IN SOME CASES BECAUSE OF THE BOMB/HIGH ENERGY EFFECT. YOUR MARKET REQUIRES INNOVATION AND NEW NEWS. NEED FOR NEW OCCASIONS AND CONSUMERS.'
  ],
  2: [
    'ON-TRADE IN 1ST TIER CITIES IS YOUR ABSOLUTE FOCUS',
    'YOU START ADDING SELECTED 2ND TIER CITIES TO YOUR SALES STRATEGY',
    'NATIONWIDE ON-TRADE SPREAD',
    'JÄGERMEISTER IS PRESENT IN ALL KEY ACCOUNTS',
    'NATIONWIDE PRESENCE'
  ],
  3: [
    'YOU ARE JUST STARTING TO BUILD THE FIRST WHOLESALE BUSINESS',
    'YOU ARE HAVNG OFF-TRADE LISTINGS IN FIRST KEY ACCOUNTS',
    'FULLY EXPAND OFF TRADE LISTINGS',
    'JÄGERMEISTER IS PRESENT IN ALL MAJOR OFF-TRADE CHANNELS, AS WELL AS DUTY FREE AND E-COMMERCE',
    'VERY HIGH DISTRIBUTION IN ALL CHANNELS, AVAILABLE IN ALL OFFLINE AND ONLINE SALES CHANNELS'
  ],
  4: [
    'BASIC WORK: IMPLEMENTING AND EXPERIMENTING WITH THE MARKETING TOOLBOX: IMPLEMENTING THE ICE COLD STRATEGY, SMALL VISIBILITY TOOLS, LIKE GLASSES AND TAP MACHINES, FOCUS ON MENU LISTINGS',
    'EXTENDING THE BASE: SUCCESFULLY USING THE MARKETING TOOLBOX WITH BASIC TOOLS: ICE COLD STRATEGY, TAP MACHINES, VISIBILITY TOOLS, SMALL EVENTS',
    'BRAND BUILDING: DIGITAL CONTENT, BIG EVENTS, CAMPAIGNS & PR, STRATEGIC BRAND MESSAGING',
    'THE ENTIRE TOOLBOX IS IN USE, ACTIVITIES ARE BIG: 360° CAMPAIGNS, INNOVATIVE DRINK STRATEGIES, HUBERTUS CIRCLE',
    'WEAR-OUT EFFECT, HIGH NEED FOR INNOVATION: THE TOOLBOX NEEDS TO BE REENERGIZED/-INVENTED TO KEEP CONSUMERS INTERESTED'
  ],
  5: [
    'NEED FOR FUNDAMENT',
    'NEED FOR GROWTH',
    'NEED FOR EMOTIONAL CONNECTION WITH THE BRAND',
    'NEED FOR VARIETY',
    'NEED FOR INNOVATION'
  ]
}
