import * as React from 'react'
import { styled } from '../../lib/styled-components'

interface IProps {
  pageCount: number
  page: number
}

export const PaginationIndicator = ({ pageCount, page }: IProps) => {
  const dots = []
  for (let pageIndex = 1; pageIndex <= pageCount; pageIndex++) {
    dots.push(<Dot selected={pageIndex === page} key={pageIndex} />)
  }

  return (<Container>
    {dots}
  </Container>)
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`

const Dot = styled.div<{ selected?: boolean }>`
  width: 16px;
  height: 16px;
  border: 1px solid ${props => props.theme.textColor};
  ${props => props.selected && `background: ${props.theme.primaryColor}`};
  margin: 0 5px;
  border-radius: 50%;
`
