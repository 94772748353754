import * as React from 'react'
import { IConfig } from '../application/lib/Types'

export const ConfigContext = React.createContext(null)

export const ConfigProvider = ConfigContext.Provider

export function useConfig(mapStateToProps?: Function): IConfig {
  const config = React.useContext(ConfigContext)

  if (typeof mapStateToProps !== 'undefined') {
    return mapStateToProps(config)
  }

  return config
}
