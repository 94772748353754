import rgba from 'polished/lib/color/rgba'
import { useEffect, useState } from 'react'
import * as React from 'react'
import { useHistory } from 'react-router'
import { ValidationContent } from '../../content/ValidationContent'
import { useConfig } from '../../../hooks/useConfig'
import { styled } from '../../lib/styled-components'
import { Headline, ScreenContainer } from '../shared/Elements'
import { HugeArrowButton } from '../shared/HugeArrowButton'
import { Ring } from '../shared/LoadingOverlay'
import { PaginationIndicator } from '../shared/PaginationIndicator'
import _ = require('lodash')

export const ValidationScreen = () => {
  const config = useConfig()
  const history = useHistory()
  const { oauthToken } = config
  const [calculating, setCalculating] = useState(false)
  const [page, setPage] = useState(1)
  const [selection, setSelection] = useState({
    1: null,
    2: null,
    3: null,
    4: null,
    5: null
  })
  const pageContent = ValidationContent[page]

  const handleOptionClick = (index: number) => {
    selection[page] = index
    setSelection(Object.assign({}, selection))
  }

  const getPhase = () => {
    const entries: [string, number][] = _.entries(
      _.countBy(selection)
    )

    const maxPhaseOccurrences = _.maxBy(entries as any, _.last)[1]
    if (maxPhaseOccurrences === 1) {
      // No phase is occurring twice
      // Use highest selected phase
      return _.maxBy(entries, _.first)[0]
    } else {
      // Use phase that occurs most times
      return _.maxBy(entries, _.last)[0]
    }
  }

  const savePhase = async() => {
    const phase = getPhase()

    const response = await fetch(`/api/countries/${config.country.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + oauthToken
      },
      body: JSON.stringify({ phase })
    })
    const country = await response.json()
    config.country = country
    history.push('/')
  }

  const previousAvailable = page > 1
  const nextAvailable = selection[page] !== null
  const onPreviousClick = () => setPage(page - 1)
  const onNextClick = () => {
    setPage(page + 1)

    if (page === 5) {
      setCalculating(true)
      savePhase()
    }
  }

  return (<ScreenContainer>
    <Headline large centered>Jägermeister Lifecycle Model</Headline>
    <Headline as="h3" centered>Validate Assignment</Headline>

    {!calculating ? <>
      <PaginationIndicator pageCount={5} page={page} />

      <ContentContainer>
        <StickyHugeArrowButton previous disabled={!previousAvailable} onClick={onPreviousClick} />
        <StickyHugeArrowButton next disabled={!nextAvailable} onClick={onNextClick} />

        <Instructions>Read all of the following statements. Then choose one, that suits your market situation best. </Instructions>

        <Options>
          {pageContent.map((option, index) => {
            return (<Option onClick={handleOptionClick.bind(null, index + 1)} key={option}>
              <RadioButton checked={selection[page] === index + 1} />
              {option}
            </Option>)
          })}
        </Options>
      </ContentContainer>
    </> : <>
      <ContentContainer centered>
        <LoadingLabel>Thank you.</LoadingLabel>
        <LoadingLabel>Your assignment is being calculated...</LoadingLabel>

        <LoadingIndicator />
      </ContentContainer>
    </>}

  </ScreenContainer>)
}

const ContentContainer = styled.div<{ centered?: boolean }>`
  position: relative;
  max-width: 890px;
  margin: 32px auto 32px auto;
  ${props => props.centered && `
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `};
`

const Instructions = styled.div`
  text-align: center;
  font-family: ${props => props.theme.primaryFontFamily};
  font-size: 20px;
  color: ${props => props.theme.textColor};
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 780px;
  margin: 32px auto 0 auto;
`

const Option = styled.div`
  display: flex;
  flex-direction: row;
  background: ${props => rgba(props.theme.textColor, 0.06)};
  border: 1px solid ${props => rgba(props.theme.textColor, 0.18)};
  padding: 30px;
  font-size: 20px;
  font-family: ${props => props.theme.primaryFontFamily};
  font-weight: bold;
  color: ${props => props.theme.textColor};
  cursor: pointer;
  text-transform: uppercase;
  line-height: 25px;
`

const RadioButton = styled.div<{ checked?: boolean }>`
  width: 35px;
  height: 35px;
  border: 1px solid ${props => props.theme.textColor};
  ${props => props.checked && `background: ${props.theme.primaryColor}`};
  border-radius: 50%;
  margin-right: 60px;
  flex: 0 0 35px;
`

const StickyHugeArrowButton = styled(HugeArrowButton)`
  top: 330px;
`

const LoadingLabel = styled.div`
  font-family: ${props => props.theme.primaryFontFamily};
  font-weight: bold;
  text-transform: uppercase;
  color: ${props => props.theme.textColor};
`

const LoadingIndicator = styled(Ring)`
  margin-top: 10px;
`
