import { useEffect, useRef, useState } from 'react'
import * as React from "react"
import { useHistory } from 'react-router'
import { useMakeImagePath } from '../../../../hooks/useMakeImagePath'
import { styled, useTheme } from '../../../lib/styled-components'
import { HoveredPhaseTooltip } from './HoveredPhaseTooltip'
import { PhaseTooltip } from './PhaseTooltip'

interface IProps {
  ownPhase?: number
  onDetailsClick?: (phase: number) => any
}

export const LifeCycleDiagram = ({ ownPhase, onDetailsClick }: IProps) => {
  const theme = useTheme()
  const history = useHistory()
  const [
    ownPhaseTooltipPosition,
    setOwnPhaseTooltipPosition
  ] = useState<{ x: number, y: number }|null>(null)
  const [
    hoveredPhaseTooltipPosition,
    setHoveredPhaseTooltipPosition
  ] = useState<{ x: number, y: number }|null>(null)
  const [
    hoveredPhase,
    setHoveredPhase
  ] = useState<number|null>(null)

  const svgRef = useRef<SVGElement>()
  const phaseRefs = {
    1: useRef<SVGRectElement>(),
    2: useRef<SVGRectElement>(),
    3: useRef<SVGRectElement>(),
    4: useRef<SVGRectElement>(),
    5: useRef<SVGRectElement>(),
  }

  useEffect(() => {
    if (!ownPhase) return setOwnPhaseTooltipPosition(null)

    const ref = phaseRefs[ownPhase]
    const rect = ref.current.getBoundingClientRect()
    const svgRect = svgRef.current.getBoundingClientRect()

    setOwnPhaseTooltipPosition({
      x: rect.left - svgRect.left + (rect.width / 2),
      y: rect.top - svgRect.top + ((rect.height - 90) / 2)
    })
  }, [ownPhase])

  const handlePhaseMouseEnter = (phase: number) => {
    setHoveredPhase(phase)

    const ref = phaseRefs[phase]
    const rect = ref.current.getBoundingClientRect()
    const svgRect = svgRef.current.getBoundingClientRect()

    setHoveredPhaseTooltipPosition({
      x: rect.left - svgRect.left + (rect.width / 2),
      y: rect.top - svgRect.top + rect.height - 90
    })
  }

  const handlePhasesMouseLeave = () => {
    setHoveredPhase(null)
    setHoveredPhaseTooltipPosition(null)
  }

  const handleValidateAssignmentClick = () => {
    history.push('/validate-assignment')
  }

  const makeImagePath = useMakeImagePath()

  return <Container>
    {ownPhaseTooltipPosition && <PhaseTooltip label="Your momentary phase" position={ownPhaseTooltipPosition} /*onButtonClick={handleValidateAssignmentClick} buttonLabel="Validate Assignment"*/ />}
    {hoveredPhase && <HoveredPhaseTooltip
        position={hoveredPhaseTooltipPosition}
        phase={hoveredPhase}
        onDetailsClick={() => onDetailsClick && onDetailsClick(hoveredPhase)} />}

    <svg
      width={893}
      height={510}
      viewBox="0 0 893 510"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      ref={svgRef}
    >
      <defs>
        <path
          d="M188.003 338.677L188 425.09H0v-66.087c95.94-4.205 158.607-10.98 188.003-20.325zM300 193.462v231.627H197v-89.864c25.669-11.85 44.766-38.903 68.43-79.978 10.341-17.95 21.555-38.578 34.57-61.785zM444 30.54v394.548H310V175.824C346.99 111.83 390.578 54.155 444 30.54zm52.26-11.316c26.453 0 44.791 4.423 60.741 10.366L557 425.09H454V26.566c13.462-4.756 27.532-7.341 42.26-7.341zM889 0c-.055 22.496-.055 164.193 0 425.09l-322-.001V33.6c27.822 11.837 51.755 26.68 106.99 26.68C765.856 60.28 783.669 1.87 889 0z"
          id="prefix__a"
        />
        <path
          d="M42.26.225c26.453 0 44.791 4.423 60.741 10.366L103 406.09H0L-.001 7.566C13.46 2.81 27.532.225 42.26.225z"
          id="prefix__c"
        />
        <path
          d="M322 0c-.055 22.496-.055 164.193 0 425.09l-322-.001V33.6c27.822 11.837 51.755 26.68 106.99 26.68C198.856 60.28 216.669 1.87 322 0z"
          id="prefix__e"
        />
        <path
          d="M134 .541V395.09H0V145.824C36.99 81.83 80.577 24.156 134 .54z"
          id="prefix__g"
        />
        <path
          d="M103 .462V232.09H0v-89.865c25.669-11.85 44.766-38.903 68.43-79.978C78.771 44.297 89.985 23.669 103 .462z"
          id="prefix__i"
        />
        <path
          d="M188.003.677L188 87.09H0V21.003C95.94 16.797 158.607 10.022 188.003.677z"
          id="prefix__k"
        />
        <filter
          x="-10.9%"
          y="-18.2%"
          width="121.8%"
          height="136.4%"
          filterUnits="objectBoundingBox"
          id="prefix__m"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur
            stdDeviation={2}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        <filter
          x="-10.9%"
          y="-18.2%"
          width="121.8%"
          height="136.4%"
          filterUnits="objectBoundingBox"
          id="prefix__n"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur
            stdDeviation={2}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        <filter
          x="-10.9%"
          y="-18.2%"
          width="121.8%"
          height="136.4%"
          filterUnits="objectBoundingBox"
          id="prefix__o"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur
            stdDeviation={2}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        <filter
          x="-10.9%"
          y="-18.2%"
          width="121.8%"
          height="136.4%"
          filterUnits="objectBoundingBox"
          id="prefix__p"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur
            stdDeviation={2}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        <filter
          x="-10.9%"
          y="-18.2%"
          width="121.8%"
          height="136.4%"
          filterUnits="objectBoundingBox"
          id="prefix__q"
        >
          <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur
            stdDeviation={2}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd" onMouseLeave={handlePhasesMouseLeave}>
        <path
          fill="#E9E3C7"
          opacity={0.096}
          style={{
            mixBlendMode: "screen",
          }}
          d="M2 0h889v468H2z"
        />
        <g transform="translate(2 43)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a"/>
          </mask>
          <use fill="#D8D8D8" xlinkHref="#prefix__a"/>
          <image
            mask="url(#prefix__b)"
            x={-14}
            y={-452}
            width={953}
            height={953}
            xlinkHref={makeImagePath('/images/gold-texture.jpg')}
          />
        </g>
        <OwnLevel visible={ownPhase === 4} transform="translate(456 62)">
          <mask id="prefix__d" fill="#fff">
            <use xlinkHref="#prefix__c" />
          </mask>
          <path
            fill="#DD5A12"
            opacity={0.7}
            mask="url(#prefix__d)"
            d="M-468-65h996v509h-996z"
          />
        </OwnLevel>
        <OwnLevel visible={ownPhase === 5} transform="translate(569 43)">
          <mask id="prefix__f" fill="#fff">
            <use xlinkHref="#prefix__e" />
          </mask>
          <path
            fill="#DD5A12"
            opacity={0.7}
            mask="url(#prefix__f)"
            d="M-581-46h996v509h-996z"
          />
        </OwnLevel>
        <OwnLevel visible={ownPhase === 3} transform="translate(312 73)">
          <mask id="prefix__h" fill="#fff">
            <use xlinkHref="#prefix__g" />
          </mask>
          <path
            fill="#DD5A12"
            opacity={0.7}
            mask="url(#prefix__h)"
            d="M-324-76h996v509h-996z"
          />
        </OwnLevel>
        <OwnLevel visible={ownPhase === 2} transform="translate(199 236)">
          <mask id="prefix__j" fill="#fff">
            <use xlinkHref="#prefix__i" />
          </mask>
          <path
            fill="#DD5A12"
            opacity={0.7}
            mask="url(#prefix__j)"
            d="M-211-239h996v509h-996z"
          />
        </OwnLevel>
        <OwnLevel visible={ownPhase === 1} transform="translate(2 381)">
          <mask id="prefix__l" fill="#fff">
            <use xlinkHref="#prefix__k" />
          </mask>
          <path
            fill="#DD5A12"
            opacity={0.7}
            mask="url(#prefix__l)"
            d="M-14-384h996v509H-14z"
          />
        </OwnLevel>
        <path
          d="M2 400c98.26-4.307 161.62-11.31 190.078-21.008 29.271-9.975 49.61-38.065 75.352-82.748 11.798-20.478 24.731-44.442 40.18-71.744 49.154-86.875 110.063-164.277 190.65-164.277s85.867 41.054 177.73 41.054c91.865 0 111.854-60.246 215.01-60.246"
          stroke="#DD5A12"
          strokeWidth={3}
          strokeLinecap="round"
        />
        <g
          fill="#E9E3C7"
          fontFamily={theme.primaryFontFamily}
          fontSize={13}
          fontWeight="bold"
        >
          <text transform="translate(35 482)">
            <tspan x={439.556} y={12}>
              {"High Level"}
            </tspan>
            <tspan x={448.974} y={27}>
              {"Plateau"}
            </tspan>
          </text>
          <text transform="translate(35 482)">
            <tspan x={641.808} y={12}>
              {"Innovation &"}
            </tspan>
            <tspan x={656.355} y={27}>
              {"Re-build"}
            </tspan>
          </text>
          <text transform="translate(35 482)">
            <tspan x={29.18} y={12}>
              {"Trial & Error"}
            </tspan>
          </text>
          <text transform="translate(35 482)">
            <tspan x={175.94} y={12}>
              {"Building the "}
            </tspan>
            <tspan x={174.536} y={27}>
              {"Foundations"}
            </tspan>
          </text>
          <text transform="translate(35 482)">
            <tspan x={303.656} y={12}>
              {"Tipping Point"}
            </tspan>
          </text>
        </g>
        <g fill="#E9E3C7" fontFamily={theme.primaryFontFamily} fontWeight="bold">
          <g filter="url(#prefix__m)" transform="translate(2 397)">
            <text fontSize={40}>
              <tspan x={86.2} y={38}>
                {"1"}
              </tspan>
            </text>
            <text fontSize={20}>
              <tspan x={63.29} y={61}>
                {"Phase"}
              </tspan>
            </text>
          </g>
          <g filter="url(#prefix__n)" transform="translate(200 397)">
            <text fontSize={40}>
              <tspan x={38.16} y={38}>
                {"2"}
              </tspan>
            </text>
            <text fontSize={20}>
              <tspan x={19.29} y={61}>
                {"Phase"}
              </tspan>
            </text>
          </g>
          <g filter="url(#prefix__o)" transform="translate(312 397)">
            <text fontSize={40}>
              <tspan x={55.26} y={38}>
                {"3"}
              </tspan>
            </text>
            <text fontSize={20}>
              <tspan x={36.29} y={61}>
                {"Phase"}
              </tspan>
            </text>
          </g>
          <g filter="url(#prefix__p)" transform="translate(454 397)">
            <text fontSize={40}>
              <tspan x={39.74} y={38}>
                {"4"}
              </tspan>
            </text>
            <text fontSize={20}>
              <tspan x={22.29} y={61}>
                {"Phase"}
              </tspan>
            </text>
          </g>
          <g filter="url(#prefix__q)" transform="translate(569 397)">
            <text fontSize={40}>
              <tspan x={149.32} y={38}>
                {"5"}
              </tspan>
            </text>
            <text fontSize={20}>
              <tspan x={130.79} y={61}>
                {"Phase"}
              </tspan>
            </text>
          </g>
        </g>
      </g>
      <rect id="Hitbox-Phase-1" ref={phaseRefs[1]} x="0" y="378.5" width="188" height="89.5" fill="transparent" onMouseEnter={handlePhaseMouseEnter.bind(null, 1)} />
      <rect id="Hitbox-Phase-2" ref={phaseRefs[2]} x="197" y="234" width="104" height="234" fill="transparent" onMouseEnter={handlePhaseMouseEnter.bind(null, 2)} />
      <rect id="Hitbox-Phase-3" ref={phaseRefs[3]} x="310" y="68" width="135" height="400" fill="transparent" onMouseEnter={handlePhaseMouseEnter.bind(null, 3)} />
      <rect id="Hitbox-Phase-4" ref={phaseRefs[4]} x="454" y="56" width="104" height="412" fill="transparent" onMouseEnter={handlePhaseMouseEnter.bind(null, 4)} />
      <rect id="Hitbox-Phase-5" ref={phaseRefs[5]} x="567" y="41" width="322" height="427" fill="transparent" onMouseEnter={handlePhaseMouseEnter.bind(null, 5)} />
    </svg>
  </Container>
}

const Container = styled.div`
  position: relative;
`

const OwnLevel = styled.g<{ visible?: boolean }>`
  display: ${props => props.visible ? 'block' : 'none'};
`
