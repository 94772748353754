import * as React from 'react'
import { breakpoint, styled } from '../../lib/styled-components'

interface IProps {
  active?: boolean
  onClick?: () => any
}

export const Hamburger = ({ active, onClick }: IProps) => {
  return (<Container onClick={onClick}>
    <Box>
      <Inner active={active} />
    </Box>
  </Container>)
}

const Container = styled.button`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10000;
  padding: 5px 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &:hover {
    opacity: 0.7; 
  }
  ${breakpoint('tablet')`
    display: none;
  `}
`

const Box = styled.div`
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative;
  perspective: 80px;
`

const Inner = styled.div<{ active?: boolean }>`
  display: block;
  top: 50%;
  margin-top: -2px;
  transition: transform 0.15s ease-out, background-color 0s 0.1s ease-out;
  ${props => props.active && `
    background-color: transparent !important;
    transform: rotateX(-180deg);
  `}
  &, &::before, &::after {
    width: 30px;
    height: 4px;
    background-color: ${props => props.theme.textColor};
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before, &::after {
    content: "";
    display: block;
    transition: transform 0.1s ease-out;
  }
  &::before {
    top: -10px;
    ${props => props.active && `transform: translate3d(0, 10px, 0) rotate(45deg);`}
  }
  &::after {
    bottom: -10px;
    ${props => props.active && `transform: translate3d(0, -10px, 0) rotate(-45deg);`}
  }  
`

