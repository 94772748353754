import * as React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { useConfig } from '../../../hooks/useConfig'
import { styled } from '../../lib/styled-components'
import { Axis, AxisLabel, Headline, LargeButton, ScreenContainer } from '../shared/Elements'
import { LifeCycleDiagram } from './life-cycle/LifeCycleDiagram'
import { PhaseDetails } from './life-cycle/PhaseDetails'

export const LifeCycleScreen = () => {
  const history = useHistory()
  const config = useConfig()
  const [detailPhase, setDetailPhase] = useState<number|null>(null)

  const handleDetailsClick = (phase: number) => setDetailPhase(phase)
  const handleDetailsClose = () => setDetailPhase(null)

  const handleNextDetailsClick = () => setDetailPhase(detailPhase + 1)
  const handlePreviousDetailsClick = () => setDetailPhase(detailPhase - 1)

  const handleExpertViewClick = () => history.push('/expert-view')
  const { baseUrl } = useConfig()

  return (<ScreenContainer>
    <Headline large centered>Jägermeister Lifecycle Model</Headline>
    <Headline as="h3" centered>Market development status phase 1-5</Headline>

    <ContentContainer>
      <DiagramContainer faded={detailPhase !== null}>
        <Axis top>
          <AxisLabel>Time</AxisLabel>
        </Axis>
        <Axis left>
          <AxisLabel rotated>Sales Volume</AxisLabel>
        </Axis>
        <LifeCycleDiagram ownPhase={config.country.phase} onDetailsClick={handleDetailsClick} />
      </DiagramContainer>
      {detailPhase !== null && <PhaseDetails
          phase={detailPhase}
          onClose={handleDetailsClose}
          nextAvailable={detailPhase < 5}
          onNextClick={handleNextDetailsClick}
          previousAvailable={detailPhase > 1}
          onPreviousClick={handlePreviousDetailsClick}
      />}
      <ButtonRow>
        <LargeButton as="a" href={`${baseUrl}/downloads/Portfolio_Development_Tool_Market_Qualification.pptx`} outlined download>Download Business Case Canvas</LargeButton>
        <LargeButton as="a" href={`${baseUrl}/downloads/Lifestyle_Modell_SKU_01.pdf`} outlined download>Download Lifecycle Model</LargeButton>
        <LargeButton outlined onClick={handleExpertViewClick}>Expert View</LargeButton>
      </ButtonRow>
    </ContentContainer>
  </ScreenContainer>)
}

const ContentContainer = styled.div`
  position: relative;
  max-width: 890px;
  margin: 0 auto;
  padding-top: 100px;
`

const DiagramContainer = styled.div<{ faded?: boolean }>`
  position: relative;
  max-width: 890px;
  height: 470px;
  ${props => props.faded && 'opacity: 0.13'};
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 80px;
  > *:not(:last-child) {
    margin-right: 24px;
  }
`
