import rgba from 'polished/lib/color/rgba'
import { useState } from 'react'
import * as React from 'react'
import { ExpertViewContent, Phases } from '../../content/ExpertViewContent'
import { styled } from '../../lib/styled-components'
import { Axis, AxisLabel, Headline, ScreenContainer } from '../shared/Elements'
import { HugeArrowButton } from '../shared/HugeArrowButton'
import some from 'lodash/some'
import { OrganizationSizeIndicator } from './expert-view/OrganizationSizeIndicator'
import { PhaseTooltip } from './life-cycle/PhaseTooltip'
import { useMakeImagePath } from '../../../hooks/useMakeImagePath'

export const ExpertViewScreen = () => {
  const [page, setPage] = useState(1)
  const pageObject = ExpertViewContent[page]

  const renderColumns = () => {
    const columns = []
    for (let phaseNumber in Phases) {
      const items = []
      const hasHighlightedItems = some(ExpertViewContent[page].items[phaseNumber], i => i.highlighted)
      ExpertViewContent[page].items[phaseNumber].forEach(item => {
        items.push(<Item highlighted={item.highlighted}>{item.label}</Item>)
      })

      let itemsStyle = null
      if (phaseNumber === '4') {
        itemsStyle = { paddingBottom: '130px' }
      } else if (phaseNumber === '5') {
        itemsStyle = { paddingBottom: '200px' }
      }

      columns.push(<Column>
        <Items style={itemsStyle} highlighted={hasHighlightedItems}>
          {phaseNumber === '1' && <Axis left>
            <AxisLabel rotated>{ExpertViewContent[page].yAxisLabel}</AxisLabel>
          </Axis>}
          {items}
        </Items>
        <Labels>
          <PhaseLabel>
            <PhaseNumber>{phaseNumber}</PhaseNumber>
            Phase
          </PhaseLabel>
          <PhaseTitle>{Phases[phaseNumber]}</PhaseTitle>

          {/*pageObject.showSizes && <OrganizationSizeIndicator size={parseInt(phaseNumber, 10)} />*/}
        </Labels>
      </Column>)
    }
    return columns
  }

  const onPreviousClick = () => setPage(page - 1)
  const onNextClick = () => setPage(page + 1)
  const previousAvailable = page > 1
  const nextAvailable = page < 3
  const phaseCount = Object.keys(Phases).length
  const makeImagePath = useMakeImagePath()

  return (<ScreenContainer>
    <Headline large centered>Jägermeister Lifecycle Model</Headline>
    <Headline as="h3" centered>Commercial Overview</Headline>

    <ContentContainer>
      <HugeArrowButton previous disabled={!previousAvailable} onClick={onPreviousClick} />
      <HugeArrowButton next disabled={!nextAvailable} onClick={onNextClick} />

      <a href="/">
        <CloseIcon src={makeImagePath('/images/icons/close.svg')} />
      </a>

      <DiagramContainer>
        <Axis top>
          <AxisLabel>Time</AxisLabel>
        </Axis>
        <Columns>
          {renderColumns()}
          {pageObject.phaseNote && <StyledPhaseTooltip position={{
            x: (100 / phaseCount * pageObject.phaseNotePosition).toFixed(1) + '%',
            y: 25
          }} label={pageObject.phaseNote} />}
        </Columns>
        {/*pageObject.showSizes && <SizeInformationText>Size of suggested distributor organization</SizeInformationText>*/}
      </DiagramContainer>
    </ContentContainer>
  </ScreenContainer>)
}

const ContentContainer = styled.div`
  position: relative;
  max-width: 890px;
  margin: 0 auto;
  padding-top: 100px;
`

const DiagramContainer = styled.div`
  position: relative;
  max-width: 890px;
  min-height: 470px;
  display: flex;
  flex-direction: column;
`

const Columns = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
`

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px dashed ${props => props.theme.primaryColor};
  &:last-child {
    border-right: 1px dashed ${props => props.theme.primaryColor};;
  }
  font-family: ${props => props.theme.primaryFontFamily};
`

const Items = styled.div<{ highlighted?: boolean }>`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: ${props => props.highlighted
    ? rgba(props.theme.primaryColor, 0.4)
    : rgba(props.theme.textColor, 0.1)};
  padding: 13px;
  min-height: 470px;
`
const Labels = styled.div`
  text-align: center;
  color: ${props => props.theme.textColor};
  min-height: 130px;
  padding-top: 10px;
`
const PhaseLabel = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`
const PhaseNumber = styled.div`
  font-size: 40px;
  font-weight: bold;
  line-height: 40px;
`
const PhaseTitle = styled.span`
  font-size: 13px;
  font-weight: bold;
  max-width: 110px;
  display: inline-block;
  height: 50px;
`

const Item = styled.div<{ highlighted?: boolean }>`
  border-radius: 2px;
  padding: 12px 5px;
  background: ${props => props.highlighted ? props.theme.primaryColor : props.theme.textColor};
  color: ${props => props.highlighted ? props.theme.textColor : props.theme.darkGreenColor};
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
`

const SizeInformationText = styled.div`
  color: ${props => props.theme.textColor};
  font-weight: bold;
  font-size: 13px;
  font-family: ${props => props.theme.primaryFontFamily};
  text-transform: uppercase;
  margin-top: 14px;
`

const StyledPhaseTooltip = styled(PhaseTooltip)`
  transform: translateX(-20px);
  text-align: left;
  width: 340px;
`

const CloseIcon = styled.img`
  position: absolute;
  top: 0;
  right: -100px;
  cursor: pointer;
  z-index: 3;
`
